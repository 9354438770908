import { useEffect, useMemo, useState } from "react";
import { GameBody } from "../components/GameBody";
import { GameHead } from "../components/GameHead";
import { initMiniApp, initPopup, postEvent, retrieveLaunchParams } from "@telegram-apps/sdk-react";
import { IGameButton, IGameLevel } from "../game/interfaces";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../api/hooks";
import { IBalance, IHttpExceptionNeedChannelToSubscribe, IUserOnEventPublic } from "../api/interfaces";
import { useTranslation } from "react-i18next";


export const Game = () => {
    const { t } = useTranslation();
    const [userCoins, setUserCoins] = useState(0);
    const [miniApp] = initMiniApp();
    const initData = retrieveLaunchParams();
    const [addCoinsStack, setAddCoinsStack] = useState<Array<number>>([]);

    const navigate = useNavigate();

    const popup = initPopup();

    const [userOnEvent, setUserOnEvent] = useState<IUserOnEventPublic>();

    useEffect(() => {
        miniApp.setHeaderColor('#1f232c');

        if (initData.platform !== 'android' && initData.platform !== 'ios' && initData.platform !== 'android_x') {
            navigate('/really');
        }
        
        setTimeout(() => {
            window.scrollBy({
                top: 10, // could be negative value
                left: 0,
                behavior: 'smooth'
            });
            setTimeout(() => {
                window.scrollBy({
                    top: -10, // could be negative value
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        }, 100);

    }, [initData.platform, miniApp, navigate]);

    const setCoins = (balance: number, coins: number) => {
        const newElements = Array.from({ length: 1 }, () => coins);
        setAddCoinsStack(() => newElements);
        setUserCoins(balance);
    };

    const initDataRaw = useMemo(() => {
        return retrieveLaunchParams().initDataRaw
    }, []);
    const token = `tma ${initDataRaw}`;

    useEffect(() => {
        axios.get<IUserOnEventPublic>(
            `${API_URL}me`,
            {
                headers: {
                    Authorization: token
                }
            }
        ).then((response) => {
            setUserOnEvent(response.data)
            setCoins(response.data.balance, 0)
        }).catch((error: AxiosError<IHttpExceptionNeedChannelToSubscribe>) => {
            const channel = error.response?.data.detail.channel;
            if (channel) {
                popup.open({
                    title: t('GamePage.needSubscribeTitle'),
                    message: t('GamePage.needSubscribeMessage') + channel,
                    buttons: [{ id: 'ok', type: 'ok' }],
                }).then(() => {
                    postEvent('web_app_open_tg_link', { path_full: channel ? channel?.replace('@', '/') : '/crazy_sousage_ru' });
                    navigate('/menu');
                })
            } else {
                throw error
            }
        })
    }, [token]);

    const tap = (button: IGameButton) => {
        axios.post<IBalance>(
            `${API_URL}tap`,
            {},
            {
                headers: {
                    Authorization: token
                },
                params: {
                    button_id: button.id
                }
            },
        ).then((response) => {
            setCoins(response.data.balance, button.score)
        }).catch((error) => {
            console.log(error)
        })
    }

    const [prevLevelMaxCoins, setPrevLevelMaxCoins] = useState(0);
    const [needNextLevelCoins, setNeedNextLevelCoins] = useState(100);
    const [currentLevel, setCurrentLevel] = useState<IGameLevel>();

    const [levels, setLevels] = useState<Array<IGameLevel>>();
    useEffect(() => {
        if (!levels) {
            axios.get<Array<IGameLevel>>(
                `${API_URL}levels/list`,
            ).then((response) => {
                setLevels(response.data.sort((a, b) => a.score - b.score))

            }).catch((error) => {
                throw error
            })
        }
    }, [levels, token]);


    useEffect(() => {
        if (levels) {
            const nextLevel = levels.sort((a, b) => a.score - b.score).find(level => level.score > userCoins);

            const prevLevel = levels.sort((a, b) => a.score - b.score).find(level => level.score <= userCoins);

            if (!nextLevel || !prevLevel) return;
            setNeedNextLevelCoins(nextLevel.score);
            setPrevLevelMaxCoins(prevLevel.score);

            let cl = levels.sort((a, b) => b.score - a.score).find(level => level.score <= userCoins)

            if (userCoins > 100_000) {
                setCurrentLevel(levels.find(
                    level => level.number === 8
                ));
            } else {
                setCurrentLevel(cl);
            }

            
        }
    }, [levels, userCoins]);

    return (
        <div className="w-screen h-screen flex flex-col">
            <GameHead
                userCoins={userCoins}
                setAddCoinsStack={setAddCoinsStack}
                addCoinsStack={addCoinsStack}
                photoBase64={userOnEvent?.photo_base64}
                currentLevel={currentLevel}
                prevLevelMaxCoins={prevLevelMaxCoins}
                needNextLevelCoins={needNextLevelCoins}
            />
            <GameBody
                handleTap={tap}
                currentLevelNumber={currentLevel?.number || 1}
            />
            {/* <div className="flex items-center w-full justify-center my-4">
                        
                <code className="text-[1.063rem] ml-4 text-center text-border-progress font-extralight">Crazy Sausage - забери свои TONs</code>
                <TonLogo width={'25px'} height={'25px'} className={'ml-2'} />
            </div> */}
        </div>
    );
}